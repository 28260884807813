
@import "~bootstrap/scss/mixins/_breakpoints.scss";

@import "./mixin";
@import "./variables";

// list of opacities that will use in the project
$opacities: (
    25: 0.25,
    50: 0.5,
    75: 0.75,
);

$heights: (
    0: 0,
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
);

@each $opacityKey, $opacityValue in $opacities {
    @each $themeKey, $themeValue in $theme-colors {
        .bg-#{$themeKey}-#{$opacityKey} {
            background-color: rgba($themeValue, $opacityValue) !important;
        }
    }
}

@each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @include media-breakpoint-up($breakpoint) {
        // .bg-xs-secondary
        @each $themeKey, $themeValue in $theme-colors {
            .bg#{$infix}-#{$themeKey} {
                background-color: $themeValue !important;
            }
        }

        // .h-xs-100
        @each $heightKey, $heightValue in $heights {
            .h#{$infix}-#{$heightKey} {
                height: $heightValue !important;
            }
        }
    }
}
