
div { }
.organization-edit {
    @mixin organization-image-form-variant($width: 120px, $height: 120px) {
        .form-dropzone-container {
            position: relative;

            .btn-dropzone-action {
                position: absolute;
                bottom: 0;
                right: 0;
                padding: 0;
                width: 32px;
                height: 32px;
                background: $white;
                @include box-shadow(0 2px 2px rgba(0, 0, 0, 0.1));
                @include border-radius(50%);
                font-size: 14px;
                &:hover, &:focus {
                    background: $gray-100;
                    color: $primary;
                    @include box-shadow(0px 4px 4px rgba(0, 0, 0, 0.2));
                }
            }
        }

        .dropzone,
        .form-control.dropzone-control,
        .form-dropzone-control {
            position: relative;
            background-clip: padding-box;
            //background-clip: border-box;

            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;

            padding: 0;
            border-width: $dropZoneBorderWidth;

            border-color: #7DC6FB;
            border-style: dashed;
            @include border-radius(6px);
            background-color: rgba(125, 198, 251, .2);

            color: #565656;
            outline: none;

            width: $width;
            height: $height;

            overflow: hidden;

            @include transition(all .15s ease);

            &:hover,
            &:focus,
            &:active,
            &.active {
                border-color: $dropZoneBorderColorActive;
                box-shadow: 0 0 0 0.25rem rgba(125, 198, 251, .2);
                @include transition($btn-transition)
            }

            &.disabled,
            &:disabled {
                opacity: 0.6;
            }

            &.is-valid, &.is-invalid {
                background-image: none;
            }

            &.form-control-rounded {
                //@include border-radius(2px)
                @include border-radius(50%)
            }

            .form-dropzone-preview {
                width: 100%;
                height: 100%;
            }

            .form-dropzone-preview-message {
                width: inherit;
                height: inherit;
                border-radius: inherit;
            }

            img, .form-dropzone-preview > img, .form-dropzone-preview-image {
                //object-fit: contain;
                object-fit: cover;
                width: inherit;
                height: inherit;
                border-radius: inherit;

                //width: 120px;
                //height: 120px;
            }

            .form-dropzone-preview-message {
                padding: 11px;
            }
        }
    }

    .organization-logo {
        @include organization-image-form-variant(120px, 120px);
    }

    .organization-image-background {
        //@include organization-image-form-variant(488px, 360px)
        @include organization-image-form-variant(100%, 360px);
    }
}
