
// include app fonts
@import '../font';

// Override bootstrap variables
@import 'mixin';
@import 'variables';
@import 'background';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// all common components
@import 'component';

// toastr
@import '~react-redux-toastr/src/styles';

// all common components
@import '~react-datepicker/dist/react-datepicker.css';

// react popper tooltip
@import '~react-popper-tooltip/dist/styles.css';

// utilities rules
@import 'utilities';

// typography rules
@import 'typography';

// modules
@import 'module';

html, body {
    margin: 0;
    height: 100%;
    overflow: hidden;
    font-size: 14px;
    @extend .font-family-primary;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
