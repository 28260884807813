@use "sass:math";

.survey {
    .survey-header {
        margin: 35px 0;

        @include media-breakpoint-up(lg) {
            margin-top: 45px;
            margin-bottom: 24px;
        }
    }

    .survey-feedback {
        margin-bottom: 20px;

        .survey-feedback-title {
            margin-bottom: 24px;

            @include media-breakpoint-up(md) {
                margin-bottom: 40px;
            }
        }

        .survey-feedback-details {
            font-weight: 400;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 100px;
        }
    }

    .survey-jumbotron, .survey-organization, .survey-report {
        margin-bottom: 40px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 120px;
        }
    }

    .survey-organization {
        padding-left: 112px;
        @include media-breakpoint-down(lg) {
            padding-left: 0;
        }
    }

    .survey-score {
        margin-bottom: 100px;

        @include media-breakpoint-down(md) {
            margin-bottom: 60px;
        }

        .survey-score-title {
            font-size: 36px;
            color: $secondary;
            margin-bottom: 62px;

            @include media-breakpoint-down(md) {
                font-size: 26px;
                margin-bottom: 30px;
            }
        }

        .score-area {
            $score-action-button-height: 40px;
            $score-action-button-shadow-height: 20px;

            .btn-score-arrow {
                margin: $score-action-button-shadow-height;
                cursor: pointer;
                border-radius: 100%;
                background-color: $primary;
                padding: $score-action-button-height;
                box-shadow: 0 0 0 20px rgba(125, 198, 251, .2);

                @include media-breakpoint-down(sm) {
                    display: none;
                }

                @include media-breakpoint-down(md) {
                    padding: math.div($score-action-button-height, 2);
                    margin: math.div($score-action-button-shadow-height, 2);
                    box-shadow: 0 0 0 math.div($score-action-button-shadow-height, 2) rgba(125, 198, 251, .2);
                    line-height: 0;

                    img {
                        width: 14px;
                    }
                }
            }

            .slider-area-wrapper {
                margin-bottom: 70px;

                @include media-breakpoint-down(md) {
                    margin-bottom: 30px;
                }

                .slider-area {
                    .rc-slider {
                        $slider-rail-height: 10px;
                        $slider-handle-height: 32px;

                        &-disabled {
                            opacity: 0.65;
                            pointer-events: none;
                            background-color: transparent;
                        }

                        &-handle {
                            margin: 0;
                            border: none;
                            width: $slider-handle-height;
                            height: $slider-handle-height;
                            background-color: $secondary;
                            transform: translate(-50%, -(math.div($slider-handle-height, 2) - math.div($slider-rail-height, 2))) !important;
                        }

                        &-rail, &-step {
                            height: $slider-rail-height;
                        }

                        &-track {
                            height: $slider-rail-height;
                            background-color: $secondary;
                        }
                    }

                    // it is left, right and bottom margin
                    $slider-margins: 20px;
                    padding: 0 $slider-margins;
                    margin-bottom: $slider-margins;
                    margin-top: math.div(($score-action-button-height + $score-action-button-shadow-height), 2);

                    @include media-breakpoint-up(md) {
                        padding: 0 $slider-margins * 2;
                        margin-bottom: $slider-margins * 2;
                        margin-top: $score-action-button-height + $score-action-button-shadow-height;
                    }

                    .slider-ruler-wrapper {
                        $gray-bg: #C4C4C4;
                        $gray-text: #7B7B7B;

                        $ruler-vertical-line-height: 24px;
                        margin-top: 18px;
                        padding-bottom: 40px;

                        .slider-ruler {
                            position: relative;

                            // horizontal line of ruler
                            &::before {
                                position: absolute;
                                content: '';
                                width: 100%;
                                height: 1px;
                                background-color: $gray-bg;
                                transform: translateY(math.div($ruler-vertical-line-height, 2));
                            }

                            .ruler-vertical-line {
                                width: 1px;
                                height: $ruler-vertical-line-height;
                                position: relative;
                                background-color: $gray-bg;

                                .vertical-line-label {
                                    margin-top: 13px;
                                    color: $gray-text;
                                    position: absolute;
                                    transform: translate(-50%, 50%);
                                }
                            }
                        }
                    }
                }
            }
        }

        .score-range-area {
            margin-bottom: 40px;
        }

        .btn-score-submit {
            background-color: $secondary;
            padding: 24px 66px;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 88px;

            .estimate-range-area {
                margin-bottom: 40px;
            }
        }
    }

    .survey-report {
        $report-message-height: 30px;
        $report-md-message-height: 25px;
        $report-lg-message-height: 55px;

        padding-top: $report-message-height;

        @include media-breakpoint-up(md) {
            padding: $report-md-message-height 0;
        }

        @include media-breakpoint-up(lg) {
            padding: $report-lg-message-height 0;
        }

        .report-callout-message {
            background-color: #F6F6F6;
            position: relative;
            padding: 5px $report-message-height;
            margin-bottom: 70px;

            &::before, &::after {
                content: '';
                position: absolute;
                left: 0;
                width: 100%;
                height: $report-message-height;
                background-color: #F6F6F6;
            }

            &::before {
                top: 0;
                transform: translateY(-#{$report-message-height});
            }

            &::after {
                bottom: 0;
                transform: translateY($report-message-height);
            }

            @include media-breakpoint-up(md) {
                padding: 20px $report-md-message-height;
                margin-bottom: 0;

                &::before, &::after {
                    height: 25px;
                }

                &::before {
                    top: 0;
                    transform: translateY(-#{$report-md-message-height});
                }

                &::after {
                    bottom: 0;
                    transform: translateY($report-md-message-height);
                }
            }

            @include media-breakpoint-up(lg) {
                padding: 20px 70px;
                margin-bottom: 0;

                &::before, &::after {
                    height: $report-lg-message-height;
                }

                &::before {
                    top: 0;
                    transform: translateY(-#{$report-lg-message-height});
                }

                &::after {
                    bottom: 0;
                    transform: translateY($report-lg-message-height);
                }
            }

            .action {
                .title {
                    font-size: 40px;
                }

                button {
                    font-size: 26px;
                }
            }
        }

        .report-actions {
            height: 100%;
            align-items: center;
            justify-content: space-around;

            &-title {
                color: #323232;
                margin-bottom: 24px;

                @include media-breakpoint-up(md) {
                    margin-bottom: 50px;
                }

                @include media-breakpoint-up(lg) {
                    margin-bottom: 74px;
                }
            }

            &-list {
                max-width: 708px;

                .btn-suggestion-action, .btn-issue-action {
                    color: $secondary;
                    border-width: 2px;
                    border-color: $secondary;
                    font-size: 22px !important;

                    &:hover, &:focus {
                        color: $primary;
                    }
                }
            }
        }

        @include media-breakpoint-up(md) {
            margin-bottom: 120px;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 119px;
        }
    }

    .survey-footer {
        min-height: 52px;
    }
}
