.alert-sealed {
    padding: 10px 16px;
    @include border-radius(6px);
    @include alert-variant($gray-100, transparentize($sealed, .2), $gray-700);

    .fa-sealed-shield {
        color: $sealed;
        opacity: .4;
    }
}
