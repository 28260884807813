
// Lato font imports

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    src:
        local('Lato'),
        url('./Lato-Medium.woff') format('woff'),
        url('./Lato-Medium.woff2') format('woff2'),
        url('./Lato-Medium.ttf') format('truetype');
}
@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 500;
    src:
        local('Lato'),
        url('./Lato-Medium-Italic.woff') format('woff'),
        url('./Lato-Medium-Italic.woff2') format('woff2');
}
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    src:
        local('Lato'),
        url('./Lato-Semi-Bold.woff') format('woff'),
        url('./Lato-Semi-Bold.woff2') format('woff2'),
        url('./Lato-Semi-Bold.ttf') format('truetype');
}
@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 600;
    src:
        local('Lato'),
        url('./Lato-Semi-Bold-Italic.woff') format('woff'),
        url('./Lato-Semi-Bold-Italic.woff2') format('woff2');
}
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src:
        local('Lato'),
        url('./Lato-Bold.woff') format('woff'),
        url('./Lato-Bold.woff2') format('woff2'),
        url('./Lato-Bold.ttf') format('truetype');
}
@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 700;
    src:
        local('Lato'),
        url('./Lato-Bold-Italic.woff') format('woff'),
        url('./Lato-Bold-Italic.woff2') format('woff2');
}
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 800;
    src:
        local('Lato'),
        url('./Lato-Extra-Bold.woff') format('woff'),
        url('./Lato-Extra-Bold.woff2') format('woff2'),
        url('./Lato-Extra-Bold.ttf') format('truetype');
}
@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 800;
    src:
        local('Lato'),
        url('./Lato-Extra-Bold-Italic.woff') format('woff'),
        url('./Lato-Extra-Bold-Italic.woff2') format('woff2');
}
