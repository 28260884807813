
#AppHeader {
    // Header variables
    $navbar-height: 88px;
    $primary-color: $secondary;

    .navbar-brand {
        width: 150px;
    }

    .avatar-image {
        width: 30px;
        color: $primary-color;
    }

    .navbar-nav .nav-item a:hover {
        opacity: .8;
        transition: .2s all;
    }

    .navbar-toggler {
        padding: 0.5rem 0.7rem;
        &:focus {
            box-shadow: 0 0 0 0.1em;
        }
    }
}
