
@import "~bootstrap/scss/mixins/_breakpoints.scss";

@import "./variables";

$radiuses: (
    0: 0,
    1: 0.5rem,
    2: 0.25rem,
    3: 0.2rem,
    4: 0.4rem,
    5: 0.5rem,
    6: 0.75rem,
);

$positions: (
    top: (
        border-top-left-radius,
        border-top-right-radius,
    ),
    end: (
        border-top-right-radius,
        border-bottom-right-radius,
    ),
    bottom: (
        border-bottom-left-radius,
        border-bottom-right-radius,
    ),
    start: (
        border-top-left-radius,
        border-bottom-left-radius,
    ),
);

@each $radiusKey, $radiusValue in $radiuses {
    // .rounded-2
    .rounded-#{$radiusKey} {
        border-radius: $radiusValue !important;
    }

    // .rounded-top-2
    @each $positionKey, $positionValue in $positions {
        .rounded-#{$positionKey}-#{$radiusKey} {
            #{nth($positionValue, 1)}: $radiusValue !important;
            #{nth($positionValue, 2)}: $radiusValue !important;
        }
    }
}

.cursor-pointer {
    cursor: pointer !important;
}
