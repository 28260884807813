
.form-check-input {
    &:checked {
        background-color: $white;

        &[type=checkbox] {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%230673C1' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
        }
    }

    &.is-invalid {
        border-color: #f91f1f !important;

        & ~ .form-check-label {
            display: inline !important;
        }
    }
}
