// all modules style dependencies

@import "./users";
@import "./layout";
@import "./issues";
@import "./dashboard";
@import "./analytics";
@import "./suggestions";
@import "./organization";

.breadcrumb {
    --bs-breadcrumb-divider: '';
    background-color: $platform;
}
