

// scss-docs-start gray-color-variables
$white:    #fff !default;
$gray-100: #FAF8F8 !default;    // $gray-lighter
$gray-200: #E6E6E6 !default;    // $gray-light
$gray-300: #CACACA !default;
$gray-400: #B0B0B0 !default;
$gray-500: #A8A8A8 !default;    // $silver
$gray-600: #7B7B7B !default;
$gray-700: #565656 !default;    // $gray
$gray-800: #494949 !default;    // $gray-darker $gray-dark
$black:    #000 !default;
// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
$grays: (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
) !default;
// scss-docs-end gray-colors-map
// fusv-enable

// Custom brand colors for theme
$transparent:           transparent;
$primary:               #7DC6FB;
$blue:                  #339AF0;
$secondary:             #0673C1;
$silver:                #A8A8A8;


$inverse:               #131e26;
$purple:                #8C78DB;
$pink:                  #f532e5;
$yellow:                #FFC700;
$green:                 #70C598;
$white:                 #ffffff;
$success:               #27c24c;
$info:                  #23b7e5;
$warning:               #ff902b;
$orange:                #E96936;
$danger:                #f91f1f;
$dark:                  #3a3f51;

// Custom color variants
$amountOfLight: 10%;

$primary-light:         lighten($primary, $amountOfLight);
$success-light:         lighten($success, $amountOfLight);
$info-light:            lighten($info, $amountOfLight);
$warning-light:         lighten($warning, $amountOfLight);
$danger-light:          lighten($danger, $amountOfLight);
$inverse-light:         lighten($inverse, $amountOfLight);
$green-light:           lighten($green, $amountOfLight);
$pink-light:            lighten($pink, $amountOfLight);
$purple-light:          lighten($purple, $amountOfLight);
$yellow-light:          lighten($yellow, $amountOfLight);
$primary-dark:          darken($primary, $amountOfLight);
$success-dark:          darken($success, $amountOfLight);
$info-dark:             darken($info, $amountOfLight);
$warning-dark:          darken($warning, $amountOfLight);
$danger-dark:           darken($danger, $amountOfLight);
$inverse-dark:          darken($inverse, $amountOfLight);
$green-dark:            darken($green, $amountOfLight);
$pink-dark:             darken($pink, $amountOfLight);
$purple-dark:           darken($purple, $amountOfLight);
$yellow-dark:           darken($yellow, $amountOfLight);

$sealed:                transparentize($danger, .2);
$platform:              #f2f2f2;


// Theme variables

// used for sidebars and content spacing
$navbar-height:                    55px;

// Top Border color for custom default cards (.card-default)
$card-default-border-color:        #cfdbe2;
//$card-cap-bg:                      rgba(0, 0, 0, 0.03);


// ---------------------
// Utilities variables
// ---------------------

$text-alpha:                  rgba(255,255,255,.5);
$text-alpha-inverse:          rgba(0,0,0,.5);

//$border-color:                rgba(0,0,0,.12);
$border-color:                #e4eaec;
//$input-btn-padding-y:         .35rem;
//$input-btn-padding-x:         .85rem;

//$input-btn-padding-y-sm:      .3125rem;
//$input-btn-padding-x-sm:      .625rem;

//$input-btn-padding-y-lg:      .625rem;
//$input-btn-padding-x-lg:      1.25rem;

//$input-height:                2.3rem; // 35px

// ---------------------
// Bootstrap Variables Override
// ---------------------

// Color system
$colors: (
    "transparent":   $transparent,
    "blue":          $blue,
    "purple":        $purple,
    "pink":          $pink,
    "yellow":        $yellow,
    "green":         $green,
    "primary":       $primary,
    "success":       $success,
    "info":          $info,
    "warning":       $warning,
    "danger":        $danger,
    'inverse':       $inverse,
    'orange':        $orange,
    'white':         $white,
    'sealed':        $sealed,
    'gray-100':      $gray-100,
    'gray-200':      $gray-200,
    'gray-300':      $gray-300,
    'gray-400':      $gray-400,
    'gray-500':      $gray-500,
    'gray-600':      $gray-600,
    'gray-700':      $gray-700,
    'gray-800':      $gray-800,

    'primary-light': $primary-light,
    'primary-dark':  $primary-dark,
    'success-light': $success-light,
    'success-dark':  $success-dark,
    'info-light':    $info-light,
    'info-dark':     $info-dark,
    'warning-light': $warning-light,
    'warning-dark':  $warning-dark,
    'danger-light':  $danger-light,
    'danger-dark':   $danger-dark,
    'green-light':   $green-light,
    'green-dark':    $green-dark,
    'pink-light':    $pink-light,
    'pink-dark':     $pink-dark,
    'purple-light':  $purple-light,
    'purple-dark':   $purple-dark,
    'inverse-light': $inverse-light,
    'inverse-dark':  $inverse-dark,
    'yellow-light':  $yellow-light,
    'yellow-dark':   $yellow-dark,
);


$theme-colors: (
    "transparent":   $transparent,
    "primary":       $primary,
    "blue":          $blue,
    "secondary":     $secondary,
    "yellow":        $yellow,
    "success":       $success,
    "purple":        $purple,
    "green":         $green,
    "orange":        $orange,
    "info":          $info,
    "warning":       $warning,
    "danger":        $danger,
    "white":         $white,
    "sealed":        $sealed,
    'gray-100':      $gray-100,
    'gray-200':      $gray-200,
    'gray-300':      $gray-300,
    'gray-400':      $gray-400,
    'gray-500':      $gray-500,
    'gray-600':      $gray-600,
    'gray-700':      $gray-700,
    'gray-800':      $gray-800,
    'platform':      $platform,
);


// Body
//
// Settings for the `<body>` element.
$body-bg:                           $white !default;
$body-color:                        $gray-700 !default;
$body-text-align:                   null !default;

//$headings-line-height:              1.1;
//$line-height-base:                  1.528571429;

//$border-radius:                     .25rem;
//$border-radius-lg:                  .375rem;

$text-muted:                        #909FA7;

// Tables
$table-hover-bg:                    #fafbfc;
$table-accent-bg:                   #fafbfc;
$table-border-color:                #eeeeee;

// Forms
$input-disabled-bg:                 $gray-100;
$input-border-color:                #eaeaea;
$input-focus-border-color:          #66afe9;
$input-placeholder-color:           #b7bac9;

// Dropdowns
$dropdown-border-color:             #e1e1e1;

// Navs
$nav-tabs-link-active-bg:           #ffffff;

// Jumbotron
$jumbotron-bg:                      #ffffff; // #fcfcfc;

// List group
$list-group-border-color:           $border-color;

// Breadcrumbs
$breadcrumb-bg:                     #fafafa;
$breadcrumb-divider-color:          #c1c2c3;
$breadcrumb-active-color:           $body-color;

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px
) !default;
// scss-docs-end grid-breakpoints

$alert-border-radius: 10px;


// Dropzone
$dropZoneBgColor: #ccc;
$dropZoneColor: #bdbdbd;
$dropZoneBorderColor: transparent;
$dropZoneBorderColorActive: darken(adjust-hue(#E6F7FF, 10), 40.39); //#3b99fc;
$dropZoneBorderColorAccepted: darken(desaturate(adjust-hue(#F6FFED, 10), 23.42), 52.94); //$green;
$dropZoneBorderColorRejected: darken(adjust-hue(#FFF1F0, 355), 31.96); //$red;
$dropZoneBorderWidth: 1px;
