
// Roboto font imports

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src:
        local('Roboto'),
        url('./Roboto-Regular.woff') format('woff'),
        url('./Roboto-Regular.woff2') format('woff2'),
        url('./Roboto-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    src:
        local('Roboto'),
        url('./Roboto-Regular-Italic.woff') format('woff'),
        url('./Roboto-Regular-Italic.woff2') format('woff2');
}
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src:
        local('Roboto'),
        url('./Roboto-Medium.woff') format('woff'),
        url('./Roboto-Medium.woff2') format('woff2'),
        url('./Roboto-Medium.ttf') format('truetype');
}
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 500;
    src:
        local('Roboto'),
        url('./Roboto-Medium-Italic.woff') format('woff'),
        url('./Roboto-Medium-Italic.woff2') format('woff2');
}
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src:
        local('Roboto'),
        url('./Roboto-Bold.woff') format('woff'),
        url('./Roboto-Bold.woff2') format('woff2'),
        url('./Roboto-Bold.ttf') format('truetype');
}
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 700;
    src:
        local('Roboto'),
        url('./Roboto-Bold-Italic.woff') format('woff'),
        url('./Roboto-Bold-Italic.woff2') format('woff2');
}
