
.analytics-page {
    .nav {
        .nav-item {
            .nav-link {
                color: $gray-700;
                @include transition($nav-link-transition);

                &.active,
                &:hover {
                    color: $secondary;
                }
            }
        }
    }
}
