
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";

@import "./mixin";
@import "./variables";

// list of font sizes that will use in the project
$font-sizes: (
    10: 10px,
    12: 12px,
    14: 14px,
    15: 15px,
    16: 16px,
    18: 18px,
    20: 20px,
    24: 24px,
    26: 26px,
    32: 32px,
    42: 42px,
);

$text-aligns: (
    start: left,
    center: center,
    end: right,
);

// list of font weights that will use in the project
$font-weights: (
    400,    // regular
    500,    // medium
    600,    // semi bold
    700,    // bold
    800,    // extra bold
);

@each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @include media-breakpoint-up($breakpoint) {
        // font sizes
        // .fz-xs-14
        @each $prop, $abbrev in (font-size: fz) {
            @each $size, $length in $font-sizes {
                .#{$abbrev}#{$infix}-#{$size} {
                    #{$prop}: $length !important;
                }
            }
        }

        // text align
        // .text-xs-center
        @each $key, $value in $text-aligns {
            .text#{$infix}-#{$key} {
                text-align: $value !important;
            }
        }

        // font weight
        // .fw-xs-400
        @each $weight in $font-weights {
            .fw#{$infix}-#{$weight} {
                font-weight: $weight !important;
            }
        }
    }
}

.text-pre-wrap {
    white-space: pre-wrap;
}
