
@import "text";

// NOTE same as base font family which was setted up into library we use
.font-family-primary {
    font-family: 'Lato', 'Arial', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.font-family-alternative {
    font-family: 'Roboto', 'Arial', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
