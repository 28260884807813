
.col-display {
    @extend .font-family-alternative;
    font-size: 14px;
    font-weight: 400;
    --bs-table-accent-bg: white;
}

.table-custom.table {
    $text-color: $gray-700;
    $border-table-color: $gray-200;
    $background-color-hover: $gray-100;
    $border-ceil-height: 54px;

    border-color: $border-table-color;

    thead {
        border: hidden;

        & > tr > th {
            border: 0;
            font-size: 14px;
            font-weight: 600;
            --bs-table-accent-bg: #FAFAFA;

            & > div {
                border-bottom: 1px solid $border-table-color;
            }
        }
    }

    tbody > tr > td {
        @extend .col-display;
    }

    & > :not(:first-child) {
        border-color: $border-table-color;
    }

    & > tr > th > div,
    & > thead > tr > th,
    & > tbody > tr > td {
        padding: 5px 15px;
        vertical-align: middle;
        border-bottom-width: 1px;
        height: $border-ceil-height;
    }

    & > tbody > tr:last-child > td {
        border: 0;
    }

    // hover & focus
    & > tbody > tr:hover > td,
    & > tbody > tr:focus-within > td {
        transition: .2s all;
        --bs-table-accent-bg: $background-color-hover;
    }
}

.table-filter.table {
    @extend .table-custom;

    $text-color: $gray-700;
    $border-table-color: $gray-200;

    thead > tr > th > div > div,
    thead > tr > th > div > button {
        font-weight: 600;
    }

    tbody > tr:last-child > td {
        border-bottom: 1px solid $border-table-color;
    }

    tbody > tr > td {
        padding: 0;

        & > a {
            @extend .customize-link;

            &:focus {
                box-shadow: none;
                border: 0;
                outline: 0;
            }
        }
    }

    .customize-link {
        @extend .col-display;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 5px 15px;
        color: $text-color;
    }
}
