
.app-invalid-feedback {
    display: block;
    width: 100%;
    //font-size: 80%;
    color: $danger;
}

.form-control {
    color: $gray-600;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 10px;
    border-color: $gray-300;
    background-color: $white;

    &.truncate {
        text-overflow: ellipsis;
    }

    &:disabled, &[readonly] {
        background-color: $gray-100;
        border-color: $gray-300 !important;
    }

    &:-ms-input-placeholder {
        color: $gray-300;
    }

    &:valid, &.is-valid,
    &:invalid, &.is-invalid {
        background-image: none;
    }

    &.form-control-sealed {
        border: 1px solid $sealed !important;

        &.is-valid:focus {
            box-shadow: none;
        }
    }
}

.form-dropzone-control {
    &:hover,
    &:focus,
    &:active,
    &.active {
        .form-dropzone-preview {
            border-color: $dropZoneBorderColorActive;
            box-shadow: 0 0 0 0.25rem rgba(125, 198, 251, .2);
            @include transition($btn-transition)
        }
    }
    &.isDragReject {
        &:hover,
        &:focus,
        &:active,
        &.active {
            .form-dropzone-preview {
                border-color: $sealed;
                box-shadow: 0 0 0 0.25rem transparentize($sealed, .6);
                @include transition($btn-transition)
            }
        }
    }
}
