.unseal-record {
    .btn-unseal {
        padding: 12px 41px;

        &:hover, &:focus, &:active {
            color: $white;
            border-color: $primary;
            background-color: $primary;
        }
    }
}
