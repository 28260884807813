.modal.confirmation-modal {
    .confirm-modal-message {
        margin-bottom: 72px;
    }

    .confirm-modal-body {
        padding: 116px 0;
    }

    .modal-dialog {
        @include media-breakpoint-up(sm) {
            max-width: 640px;
        }
    }

    .btn-confirm-modal {
        padding: 13px 94px;

        &:hover, &:focus, &:active {
            color: $white;
            border-color: $primary;
            background-color: $primary;
        }
    }
}
