
.btn {
    @extend .font-family-alternative;
    @extend .fw-500;
    @extend .fz-16;

    padding-top: 11px;
    padding-bottom: 11px;
    border-radius: 10px;
}

.btn-extended {
    @each $color, $value in $theme-colors {
        &.btn-outline-#{$color}-white {
            $active-color: darken(desaturate($value, 0.06), 34.71);
            background-color: $white;
            @include button-outline-variant($value, $active-color, $white, $active-color, $active-color);
        }
    }
}
